.nav-bar{
	z-index:1000;
	width:100%;
	top:0;

	&.affix{
		box-shadow:0 0 8px rgba(#000,0.8);
		background: #FFF;
		animation: fadeInDown 1s linear;
		@import '_nav-content-open.scss';
	}

	@media (max-width:$screen-sm-max){
		@import '_nav-content-open.scss';
	}

	.aux{
		opacity:0;
		background: rgba(#000,0.6);
		transition:opacity 1s linear;
	}

	&.open{
		.aux{
			position:fixed;
			top:0;
			left:0;
			opacity:1;
			width:100%;
			height:100%;
			z-index:1000;
		}

		.nav-content{
			left:0;
		}
	}

	.visible-when-open{
		display: none;
	}
}

.nav-header{
	display:flex;
	justify-content:space-between;
	height:60px;
	border-bottom:1px solid #CCC;

	.brand{
		padding:5px 15px;
		width:100px;
	}
}

.btn-toggle-menu-topo{
	border-radius:0;
	@include button-variant($color-toggle-menu, $bg-toggle-menu,$border-toggle-menu);
}

.nav-content{
	transition: all 1s linear;

	ul{
		list-style:none;
		padding-left:0;
		margin-bottom:0;
	}
}

.nav-bar.closed{
	@import "_nav-content-closed.scss";
}

.busca-mobile{
	padding:10px 15px;

	.form-control,
	.btn{
		height:40px;
		border-radius:0;
	}
}

#utilits{
	li{
		&.meu-carrinho{
			background: $bg-icon-carrinho;

			&:hover{
				background: darken($bg-icon-carrinho,10%);
			}
		}
		&.meus-pedidos{
			background: $bg-icon-lista;

			&:hover{
				background: darken($bg-icon-lista,10%);
			}
		}
		&.meus-dados{
			background: $bg-icon-user;

			&:hover{
				background: darken($bg-icon-user,10%);
			}
		}
		&.log-in{
			background: $bg-icon-login;

			&:hover{
				background: darken($bg-icon-login,10%);
			}
		}
		&.log-off{
			background: $bg-icon-logof;

			&:hover{
				background: darken($bg-icon-logof,10%);
			}
		}
		&.enderecos{
			background: $bg-icon-enderecos;

			&:hover{
				background: darken($bg-icon-enderecos,10%);
			}
		}
	}
}

.nav-bar .dropdown-menu{
	border-radius:0;
	box-shadow:none;
	float:none !important;
	margin-top:0;
	padding-top:0;
	padding-bottom:0;
	border-radius:0;
	z-index:10;

	.nome-categoria{
		display:flex;
		align-items:center;

		a{
			display:block;
			width:100%;
			white-space:normal;
		}

		.btn{
			height:auto;
			margin:5px;
		}

		a,
		.btn{
			padding:6px 12px;
		}
	}
}

.drop-filtro{
	position:absolute;
	min-width:220px;
	top:100%;
	z-index:1000;
	font-size:14px;
	background:#FFF;
	box-shadow:0 2px 4px rgba(#000,0.8);
	display: none;
	margin-top:10px;
	padding-top:5px;
	border:1px solid rgba(#000,0.4);

	&:before{
		content:'';
		display: block;
		position:absolute;
		bottom:100%;
		left:50%;
		margin-left:-10px;
		border-left:10px solid transparent;
		border-right:10px solid transparent;
		border-bottom:10px solid #FFF;
		filter: drop-shadow(0 -1px rgba(#000,0.4));
	}

	li{
		padding:10px 15px;
		cursor:pointer;

		&:hover{
			background:#DDD;
		}

		&.active{
			background: $theme;
			color:#FFF;
		}
	}
}

.open .drop-filtro{
	display: block !important;
}