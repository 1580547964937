.rodape{
	border-top: $border-top-footer;
	background: $bg-rodape;
	padding-top:30px;
	color: $color-footer;

	a:hover{
		color: $color-footer;
	}

	.title-footer{
		font-family: 'open_sanslight';
		font-size:20px;
		
		border-bottom:1px solid $border-title-footer;

		span{
			display: inline-block;
			padding:5px;
			border-bottom:3px solid $border-span-title-footer;
			margin-bottom:-2px;
		}
	}

	ul{
		list-style:none;
		padding-left:0;

		a{
			display: block;
			padding:5px;
		}
	}

	.creditos{
		border-top:1px solid #CCC;
		padding:10px 0;
		font-size:12px;

		@media (min-width:$screen-sm-min){
			.col-sm-6{
				&:last-child{
					text-align:right;
				}
			}
		}

		@media (max-width:$screen-xs-max){
			.col-sm-6{
				text-align:center;

				&:last-child{
					margin-top:10px;
				}
			}
		}
	}


	.midia-footer + .midia-footer{
		margin-left:15px;
	}

	.midia-footer,
	.fa{
		position:relative;

		&:after{
			content:'';
			display: block;
			width:10px;
			height:10px;
			left:calc(50% - 5px);
			top:calc(50% - 5px);
			background: rgba(#FFF, 0.6);
			position:absolute;
			border-radius:100%;
			opacity:0;
		}

		&:hover:after{
			animation: infiteRipple 1s infinite;
		}
	}

	.fa{
		color:$theme;
		font-size:20px;
		width:(39 / 20) * 1em;
		height:(39 / 20) * 1em;
		line-height:(39 / 20) * 1em;
		border:2px solid currentColor;
		text-align:center;
		border-radius:100%;
		vertical-align:middle;
		text-decoration:none;
		transition:color 0.6s linear;
		margin:0 7px;

		&:first-child{
			margin-left:0;
		}

		&:last-child{
			margin-right:0;
		}

		&:hover{
			color:darken($theme,10%);
		}

	}

}

@include keyframes(infiteRipple){
	0%{
		transform:scale(0);
		opacity:0.6;
	}
	100%{
		transform:scale(10);
		opacity:0;
	}
}

.dados{
	padding-left:40px;
	@include clearfix;

	.icon{
		width:40px;
		float:left;
		margin-left:-40px;
	}
}


.btn__whatsapp,
.btn__whatsapp .btn-wrapper{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 50%;
}

.btn__whatsapp .btn-wrapper{
	overflow: hidden;
}

.btn__whatsapp {
	background: #01AB4C;
	position: fixed;
	left: 15px;
	bottom: 15px;
	color: #FFF;
	z-index: 3090;
	font-size: 40px;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.8);
}

.btn__whatsapp svg{
	fill: #fff;
}