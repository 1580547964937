.table-carrinho{
	margin:-15px -15px;

	.table{
		& > thead{
			font-size:17px;

			& > tr{
				& > th:last-child{
					border-left:1px solid #CCC;
				}
			}
		}
		& > tbody{
			& > tr{
				& > td:last-child{
					border-left:1px solid #CCC;
					font-size:16px;
				}
			}
		}
	}

	img{
		margin-bottom:10px;
	}

	.nome-produto{
		font-size:17px;
	}

	@media (min-width: $screen-md-min){
		.foto-produto{
			float:left;
			margin-right:30px;
		}
	}

	.input-group{
		width:180px;
		margin-left:auto;
		margin-right:auto;

		.btn{
			border-radius:0;
		}

		.form-control{
			text-align:center;
		}
	}

	.h4{
		font-family: 'open_sansbold';
	}

	.tr-ttl{
		font-size:18px;
	}

	.custon-radio{
		.circle{
			display: inline-block;
			width:1em;
			height:1em;
			border-radius:100%;
			border:1px solid #333;
			background:#FFF;
			position:relative;

			&:before{
				content:'';
				display: block;
				position:absolute;
				width:100%;
				height:100%;
				border:2px solid #FFF;
				background: $theme2;
				border-radius:100%;
				left:0;
				top:0;
				transform:scale(0);
				transition:all 0.2s linear;
			}
		}

		input:checked + .circle:before{
			transform:scale(1);
		}

		input{
			position:fixed;
			left:100%;
		}
	}
}