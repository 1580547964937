.wrapper{
	min-height:100vh;
}

html{
	overflow-x:hidden;
}

// .container{
// 	max-width:1000px;
// }