@mixin passo-variant($className: 'passo-default', $bg: #FFF, $cor: #000){
	&.#{$className}{
		background: $bg;
		color: $cor;

		&:before{
			background: $bg;
		}
	}
}

.passos-compra{
	padding-left:0;
	display:flex;
	list-style:none;
	
	@media(max-width:$screen-sm-max){
		flex-wrap:wrap;
	}

	li{
		width:160px;
		position:relative;
		border:1px solid #ccc;
		display:flex;
		align-items:center;
		padding:10px;
		font-size:12px;
		margin-bottom: 15px;

		& + li{
			padding-left: 30px;
		}

		&:first-child{
			border-radius:4px 0 0 4px;
		}

		&:last-child{
			border-radius:0 4px 4px 0;

			&:before,
			&:after{
				display: none;
			}
		}

		&:before{
			content:'';
			display: block;
			width:10px;
			height:calc(100% + 2px);
			clip-path: polygon(0 0, 100% 50%, 0 100%);
			background:#FFF;
			position:absolute;
			left:100%;
			top:-1px;
			z-index:2;
		}

		&:after{
			content:'';
			display: block;
			width:10px;
			height:calc(100% + 2px);
			clip-path: polygon(0 0, 100% 50%, 0 100%);
			background:#CCC;
			position:absolute;
			left:100%;
			margin-left:1px;
			top:-1px;
			z-index:1;
		}

		@include passo-variant('success',materialColor('green', 'darken-2'), #FFF);
		@include passo-variant('primary',materialColor('indigo', 'darken-2'), #FFF);
		@include passo-variant('warning',materialColor('orange', 'darken-2'), #FFF);
		@include passo-variant('danger',materialColor('red', 'darken-2'), #FFF);
	}
}