.categorias-side{
	.heading{
		padding:10px 15px;
		text-align:center;
		color: $color-heading-categorias-side-index;
		background: $bg-heading-categorias-side-index;
		margin-bottom:15px;
		font-size:17px;
		border-radius:4px;
	}

	.body{
		background: #FFF;
	}

	a:hover{
		text-decoration:none;
	}

	.nome{
		position:relative;

		a{
			display: block;
			padding:10px 15px;
			background: $bg-nomde-categoria-side;
			color: $color-nomde-categoria-side;
		}

		&:after{
			content:'';
			display: block;
			position:absolute;
			left:10px;
			top:100%;
			border-left:10px solid transparent;
			border-right:10px solid transparent;
			border-top:10px solid $bg-nomde-categoria-side;
		}
	}

	ul{
		list-style:none;
		padding-left:0;

		a{
			display: block;
			padding:10px 15px 10px 45px;

			&:before{
				content:'-';
				margin-left:-10px;
				width:10px;
				display: inline-block;
				position:absolute;
			}
		}

		a:hover,
		.active a{
			background: #EEE;
			color: $color-categoria-side-hover;
		}
	}
}