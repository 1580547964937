@media (min-width:$screen-md-min){
	.nav-header,
	.busca-mobile{
		display: none;
	}

	.main-menu{
		display:flex;
		justify-content:space-between;
	}

	.nav-content{
		max-width: 1170px;
		padding-left:15px;
		padding-right:15px;
		margin-left:auto;
		margin-right:auto;

		.brand,
		#utilits{
			padding-top:20px;
		}
	}

	.main-menu{
		margin-left:-15px;
		margin-right:-15px;
	}

	#menu,
	#utilits{
		display: flex;
		padding-left:15px;
		padding-right:15px;

		li{
			position:relative;

			&:hover,
			&.open{
				.legenda{
					display: block;
				}
			}
		}

		.enderecos{
			display: none;
		}

		.legenda{
			display: inline-block;
			position:absolute;
			top:100%;
			left:0;
			background: $bg-tooltip-user-utilits;
			color:$color-tooltip-user-utilits;
			display: none;
			padding:6px;
			font-size:12px;
			border-radius:4px;
			margin-top:10px;
			animation: fadeIn 0.3s linear;
			z-index:10;
			filter:drop-shadow(2px 2px 4px rgba(#000,0.4));

			&:before{
				content:'';
				display: block;
				position:absolute;
				bottom:100%;
				left:10px;
				border-left:10px solid transparent;
				border-right:10px solid transparent;
				border-bottom:10px solid $bg-tooltip-user-utilits;
			}
		}
	}

	#utilits{
		li{
			width:50px;
			height:50px;
			display:flex;

			a{
				width:100%;
				display:flex;
				align-items:center;
				justify-content:center;
			}
		}
	}

	.brand{
		width: (100% / 12) * 4;
		padding:0 15px;
	}

	#menu{
		a{
			display: block;
			padding:46px 10px 10px 10px;

			&:hover{
				text-decoration:none;
			}
		}

		li{
			border-top:5px solid transparent;

			&.active,
			&:hover{
				border-color: $border-menu-ativo;
				a{
					color:$hover-menu;
				}
			}
		}

		li + li{
			margin-left:10px;
		}
	}

	.categorias-topo{
		margin-top:20px;
		padding-top: 20px;
		display:flex;
		justify-content:space-between;

		.btn,
		.form-control{
			height:40px;
		}
	}

	#cleitinho{
		background-color:#FFF;
	}

	.btn-categorias{
		@include button-variant($color-btn-categorias,$bg-btn-categorias-categorias,$bg-btn-categorias-categorias);
	}

	.btn-filtro{
		border-radius:4px 4px !important;
		@include button-variant($color-btn-filtro,$bg-btn-filtro-categorias,$bg-btn-filtro-categorias);
	}

	#categorias-topo{
		padding-right: 15px;
	}

	.busca-por-categoria{
		padding-left:15px;

		.form-control{
			border-right: none;
			box-shadow:none;
		}
	}

	.btn-buscas{
		@include button-variant(#000, transparent, #CCC);
		border-radius: 0 0;
		border-left:none;
	}

	#categorias-content{
		width:220px;

		.sub{
			width: 220px;
			left:100%;
			top:0;
			margin-left:10px;

			&:before{
				content:'';
				border-right:10px solid #FFF;
				border-top:10px solid transparent;
				border-bottom:10px solid transparent;
				position:absolute;
				right:100%;
				top:5px;
				filter: drop-shadow(-1px 0 #CCC);
			}
		}

		.btn{
			background-color:$bg-categoria-legal;
			transition:color,background-color 0.5s linear;

			&:hover{
				background-color:$bg-categoria-legal-hover;
				color:$color-categoria-legal-hover;
			}

		}

	}
}