.panel-pagamento{
	border:none;

	.panel-heading{
		border-radius:4px 4px;
		border:1px solid #CCC;
	}

	.panel-body{
		border:none;
		padding:15px 0;
	}
}

.btn-pagamento{
	display:inline-flex;
	white-space:normal;
	width:220px;
	FONT-SIZE:12PX;

	@include button-variant($cor-bnt-pagamento,$bg-btn-pagamento,$bg-btn-pagamento);

	span{
		padding:6px 10px;
	}

	span.fa{
		display: flex;
		align-items:center;
		border-right:1px solid $border-separador-btn-pagamento;
		padding-left:0;
	}

	span.text-block{
		font-family: 'open_sansbold';
		display: inline-block;
		text-align:left;
		border-left:1px solid $border-separador-btn-pagamento;
		padding-right:0;
	}
}