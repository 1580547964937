@media (min-width:$screen-md-min){
	// Desktop - >= 990px

	.carousel-produtos-index{
		.heading{
			.col-md-6:last-child{
				text-align: right;
			}
		}
	}
}