@media (max-width:$screen-xs-max){
	// Celphones - < 768

	.mgb-reset-gtr{
		margin-left:-15px;
		margin-right:-15px;
	}

	.btn-xs-blk{
		width:100% !important;
	}
}