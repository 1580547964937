.modal{
	.modal-content{
		border-radius:0;
	}

	.modal-header{
		font-family: 'open_sansbold';
		font-size:18px;
	}

	&.success{
		.modal-header{
			background: materialColor('green', 'darken-3');
			color:#FFF;
		}

		.btn-modal{
			@include button-variant(#FFF,materialColor('green', 'darken-3'),materialColor('green', 'darken-3'));
		}
	}
	
	&.danger {
		.modal-header{
			background: materialColor('red', 'darken-3');
			color:#FFF;
		}

		.btn-modal{
			@include button-variant(#FFF,materialColor('red', 'darken-3'),materialColor('red', 'darken-3'));
		}
	}

	&.warning{
		.modal-header{
			background: materialColor('orange', 'darken-3');
			color:#FFF;
		}

		.btn-modal{
			@include button-variant(#FFF,materialColor('orange', 'darken-3'),materialColor('orange', 'darken-3'));
		}
	}

	&.info{
		.modal-header{
			background: materialColor('indigo', 'darken-3');
			color:#FFF;
		}

		.btn-modal{
			@include button-variant(#FFF,materialColor('indigo', 'darken-3'),materialColor('indigo', 'darken-3'));
		}

	}
}