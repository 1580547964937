body{
	background: url(#{$imgs}/bg-corpo.png) center center;
	background-size:cover;
	background-attachment:fixed;
}

main{
	position:relative;
	z-index:2;
	padding-left:15px;
	padding-right:15px;

	.container{
		position:relative;
	}

	.container:before{
		content:'';
		display: block;
		width:100%;
		left:0;
		bottom:0;
		height:6000cm;
		z-index:-10;
		position:absolute;
	}
}