body{
	font-family: 'open_sansregular';
	font-size:15px;
}

a{
	color:inherit;
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.text-danger{
	color: #C70719;
}

.title-media-iterna{
	border-bottom:2px solid $theme2;
	font-size:20px;
	padding-bottom:10px;
	font-family: 'open_sansbold';
	margin-top:40px;
}

.circle-midia{
	border:2px solid #A3A3A2;
	color:#A3A3A2;
	display: inline-block;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	border-radius:100%;
	font-size:18px;
	margin:15px;

	&:hover{
		color:$theme;
		border-color:$theme;
	}
}

.panel-pagamento{
	h2{
		margin:0;
		font-family: 'open_sansbold';
		color:#333;
		font-size:20px;
	}

	p{
		margin-bottom:0;
	}
}

.ops-bld{
	font-family: 'open_sansbold';
}

.paginacao{ 
	& > *{
		& + *{
			margin-left:-5px;
		}
	}
}