.flex-prod-detail{
	display:flex;
	margin:-15px;

	.head{
		padding:15px;
		border-bottom:1px solid #CCC;
		font-family: 'open_sansbold';
		font-size: 18px;
	}

	.content{
		padding:15px;
	}

	@media (max-width: $screen-xs-max){
		flex-wrap:wrap;

		.block{
			width:100%;

			& + .block{
				border-top:1px solid #CCC;
			}
		}
	}

	@media (min-width:$screen-sm-min) and (max-width: $screen-sm-max){
		flex-wrap:wrap;

		.block{
			width:50%;

			&.medias{
				width:100%;
				border-bottom:1px solid #CCC;
				padding-bottom:30px;
			}

			&.prices{
				border-left:1px solid #CCC;
				width:30%;
			}

			&.description{
				width:70%;
			}
		}
	}

	@media (min-width: $screen-md-min){
		.block{
			width: 40%;

			&.prices{
				border-left:1px solid #CCC;
				width: 20%;
			}
		}
	}

	.nav-tabs{
		li{
			width:50%;
			a{
				border-radius: 0;
				font-size:16px;
				font-family: 'open_sansbold';
			}

			&.active a{
				color: materialColor('green','darken-3');
			}
		}
	}

	.embed-responsive{
		padding-bottom:100%;
	}

	.details{
		max-width:400px;
		margin-left:auto;
		margin-right:auto;
	}

	.album{
		display:flex;
		flex-wrap:wrap;
		margin-left: -10px;
		margin-right: -10px;

		figure{
			width:25%;
			text-align:center;
			padding:10px;
		}
	}
}