.internas{
	padding-top:20px;
	
	& > *:last-child{
		padding-bottom:60px;
	}
}

.box-internas{
	.heading{
		padding:10px;
		border-radius:4px;
		background: $bg-heading-box-internas;
		color:$color-heading-box-internas;

		h1{
			margin-top:0;
			margin-bottom:0;
			font-family: 'open_sanslight';
			font-size:30px;
		}
	}

	.body{
		margin-left:5px;
		margin-right:5px;
		border-left:1px solid #7D7D7D;
		border-right:1px solid #7D7D7D;
		border-bottom:1px solid #7D7D7D;
		border-radius: 0 0 4px 4px;
		padding:15px;
	}
}

.alertas{
	position:fixed;
	bottom:10px;
	right:10px;
	z-index:1000;

	.alert{
		border-radius:0;
		color:#FFF;
		box-shadow: 0 0 4px rgba(#000,0.8);
		animation:rubberBand 1s linear;
		max-width:380px;

		@media (max-width: $screen-xs-max){
			width: 220px;
		}

		h2{
			font-size:18px;
		}
	}
}

.alerta-danger{
	background: materialColor('red', 'darken-3');
	color: #FFF;
}

.alerta-success{
	background: materialColor('green', 'darken-3');
	color: #FFF;
}

.alerta-warning{
	background: materialColor('orange', 'darken-2');
	color: #FFF;
}

.alerta-info{
	background: materialColor('light-blue', 'darken-4');
	color: #FFF;
}

.alert-default{
	background: #EEE;
}

.box-internas-side{
	color:#666666;

	.heading{
		background: $bg-box-internas-side;
		font-family: 'open_sansbold';
		font-size: 20px;
	}

	.panel{
		border-color:transparent;

		.panel-heading{
			border-radius: 4px 4px 4px 4px;
			border-left:1px solid #CCC;
			border-right:1px solid #CCC;
			border-top:1px solid #CCC;
			font-family: 'open_sansbold';
			font-size:16px;
		}
	}

	.table-endereco{
		margin-left:-15px;
		margin-right:-15px;
		margin-bottom:0;

		caption{
			padding:10px;
			background: #EEE;
			border-radius:4px;
			margin-bottom:10px;
			border:1px solid #CCC;
			font-family: 'open_sansbold';
			font-size:16px;
			margin-left:5px;
			margin-right:5px;
		}

		.table{
			margin-bottom:0;
		}

		.table thead {
			& > tr{
				& > th{
					background: #EEE;
					border-top:1px solid #CCC;

					&:first-child{
						border-radius:4px 0 0 4px;
						border-left:1px solid #CCC;
					}

					&:last-child{
						border-radius:0 4px 4px 0;
						border-right:1px solid #CCC;
					}
				}
			}
		}
	}
}

#localizacao{
	position:relative;
	padding-bottom:50%;

	iframe{
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		top:0;
		border:none;
	}
}

.nav-side{
	padding:0;
	list-style:none;
	margin-left:-15px;
	margin-right:-15px;

	a{
		display: block;
		padding:10px 15px;
	}

	li{
		margin-bottom:5px;
	}

	.active a,
	a:hover{
		background:#DBDBDB;
		font-weight:bold;
		text-decoration:none;
		color:#444;
	}
}

.meus-enderecos{

	& + .meus-enderecos{
		margin-top:20px;
		.heading-enderecos{
			border-top:1px solid #CCC;
		}
	}

	.heading-enderecos{
		margin-left:-15px;
		margin-right:-15px;
		padding:10px 15px;
		font-family: 'open_sansbold';
		border-bottom:1px solid #CCC;
	}
}