.produto{
	width:248px;
	max-width:100%;
	text-align:center;

	.foto{
		position:relative;
		padding-bottom: (200 / 248) * 100%;
		margin-bottom:10px;

		lazyimage{
			position:absolute;
			display: flex;
			align-items:center;
			justify-content:center;
			width:100%;
			height:100%;
			border:1px solid #CCC;
			border-radius:10px;
			overflow:hidden;
			background: url(#{$imgs}/loading.svg) center center no-repeat #FFF;

			&[loaded="true"]{
				background-image: none !important;
			}
		}
	}

	.btns{
		display:flex;
		justify-content:space-between;
		flex-wrap:wrap;
		margin-top:15px;

		.btn{
			margin-bottom:15px;
		}
	}

	@media (max-width: $screen-xs-max){
		.btn{
			width:100%;
		}
	}

	@media (min-width: $screen-sm-min){
		.btn{
			width: calc(50% - 15px);
		}
	}

	.nome{
		margin-bottom:10px;
	}

	.por{
		font-size:1.5em;
	}
}

.owl-item .produto,
.item.active .produto{
	margin-left:auto;
	margin-right:auto;
}