$icons: (
	'aspas': -98px -134px 35px 34px,
	'br': -59px -91px 48px 33px,
	'carrinho-topo': -209px -38px 25px 30px,
	'en': -151px 0px 48px 33px,
	'envelope-o': 0px -183px 27px 20px,
	'envelope': -54px -37px 28px 28px,
	'es': 0px -91px 49px 33px,
	'facebook': -151px -43px 39px 39px,
	'fone-bar-topo': -247px 0px 11px 29px,
	'gv8': 0px 0px 87px 27px,
	'login-logoff': -117px -91px 20px 22px,
	'map-marker': -143px -134px 28px 28px,
	'meus-dados-topo': -209px -78px 27px 27px,
	'next-banner': 0px -37px 44px 44px,
	'pin-topo': -209px -115px 21px 30px,
	'prev-banner': -97px 0px 44px 44px,
	'telefone-f': -209px 0px 28px 28px,
	'twitter': 0px -134px 39px 39px,
	'user-topo': -97px -54px 22px 25px,
	'whtsapp': -151px -92px 31px 31px,
	'youtube': -49px -134px 39px 39px,
);

.icon{
	display: inline-block;
	vertical-align: middle;
	background-image: url(#{$imgs}/sprite.png);
	background-repeat:no-repeat;
	background-size: 258px 203px;
}


@mixin sprites($icons) {
    @each $icon,$data in $icons{
        .#{$icon}{
            width: nth($data,3);
            height: nth($data,4);
            background-position: #{nth($data,1)} #{nth($data,2)};
        }
    }
}

@include sprites($icons);