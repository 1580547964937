.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}

lazyimage{
    display: block;
}


.noround{
    border-radius:0;
}

.img-responsive{
    display: inline-block;
}

.lockscroll{
    overflow:hidden;
}

.btn{
    outline:none !important;
}
.btn-comprar{
    @include button-variant($color-btn-comprar,$bg-btn-comprar,$bg-btn-comprar);
}

.carousel-responsive{
    .item.active{
        @media (min-width: $screen-md-min){
            display:flex;
            flex-wrap:wrap;
            margin-left:-15px;
            margin-right:-15px;
        }

        @media (max-width: $screen-sm-max){
            margin-left:-15px;
            margin-right:-15px;
            @include clearfix;
        }
    }
}

.visible-desktop{
    @media (max-width: $screen-sm-max){
        display: none;
    }
}

.btn-theme{
    @include button-variant(#FFF, $theme, $theme);
}

.btn-theme2{
    @include button-variant(#FFF, $theme2, $theme2);
}

.pdlr-15{
    padding-left:15px;
    padding-right:15px;
}

.bdb-ccc{
    border-bottom:1px solid #CCC;
}

.bdt-ccc{
    border-top:1px solid #CCC;
}

.vitrine{
    @media (min-width: $screen-sm-min){
        display:flex;
        flex-wrap: wrap;
    }
}

.rating{
    unicode-bidi: bidi-override;
    direction: rtl;
    margin-bottom:10px;
    text-align:left;

    label{
        margin:0 5px;
        padding:0;
        font-size:20px;

        &:hover .fa:before,
        &:hover ~ label .fa:before,
        &.active .fa:before,
        &.active ~ label .fa:before{
            content:"\f005";
            color: materialColor('orange', 'darken-2');
        }
    }

    input{
        position:fixed;
        top:100%;
    }
}

#avaliacoes{
    .carousel-inner{
        padding-left:35px;
    }
}

.alert{
    border-radius:0;

    &.alert-success{
        background: materialColor('green','darken-3');
        color:#FFF;
    }
}