@import "components/_bar-topo.scss";
@import "components/_nav-bar.scss";

.nav-header{
	background:#FFF;
}

.topo{
	position:relative;
	z-index:1000;
}

.topo .busca-por-categoria .btn-buscas{
	background-color:#FFFFFF !important;
}

.nav-bar .nav-content ul a{
	white-space:normal;
}

.news-index-top{
	background: $news-index-top-legal;
	color:#FFF;
	display:flex;
	padding:10px;
	margin-bottom:30px;

	@media (max-width:$screen-xs-max){
		flex-wrap:wrap;

		& > label{
			text-align:center;
			margin-bottom:15px;
		}

		& > * {
			width:100%;
		}

		.btn{
			width:100%;
		}
	}

	@media (min-width:$screen-sm-min) and (max-width:$screen-sm-max){
		flex-wrap:wrap;

		& > label{
			width:100%;
		}

		.flx-item-form{
			width: calc(50% - 40px);
			align-items:center;
			display:flex;
			white-space:nowrap;

			label{
				margin-right:10px;
			}

			& + .flx-item-form{
				margin-left:10px;
			}

			&:last-child{
				width:60px;
			}
		}

		.form-group{
			margin-bottom:0;
			width:100%;
		}
	}


	@media (min-width:$screen-md-min){
		align-items:center;

		& > label{
			border-right:2px solid #FFF;
			margin-right:15px;
		}

		.form-group{
			margin-bottom:0;
		}

		label{
			margin-bottom:0;
		}

		& > *{
			flex-grow: 1;
			align-items:center;
		}

		.flx-item-form{
			display:flex;
			padding:0 5px;

			& > *{
				flex-grow:1;
			}
		}
	}
}