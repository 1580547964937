$imgs: '../imagens/estrutural';
$fonts: '../fonts';

// TEMA====================================
$theme: #CD5C5C;
$theme2: darken(#CD5C5C, 10%);

//=========================================
// ===============| TOPO :|===============|
//=========================================
// Barra com os dados de contato+++++++++++
$bg-barra-topo: $theme;
$color-barra-topo: #FFF;

// MENU TOPO :++++++++++++++++++++++++++++++
$color-toggle-menu: #FFF;
$bg-toggle-menu: $theme2;
$border-toggle-menu: $theme2;

$bg-menu-open: #FFF; // Quando nav-bar.open

// Menu Navegação
$border-menu-ativo: #343434;
$hover-menu: $theme;

// News Index Top
$news-index-top-legal:#CCCCCC;

// User Utilits
$bg-icon-carrinho: #BD1962;
$bg-icon-lista: #85C82A;
$bg-icon-user: #DEC626;
$bg-icon-login: #23A2C3;
$bg-icon-logof: #EC1B3B;
$bg-icon-enderecos: #00796B;

$bg-tooltip-user-utilits: $theme;
$color-tooltip-user-utilits: #FFF;

// Categorias topo
$color-btn-categorias: #FFF;
$bg-btn-categorias-categorias: $theme;

// FILTRO
$color-btn-filtro: #FFF;
$bg-btn-filtro-categorias: $theme2;

// Categorias

$bg-categoria-legal:#E3E3E3;

$bg-categoria-legal-hover:#454545;
$color-categoria-legal-hover:#FFF;

// SIDE BAR INDEX
$bg-heading-categorias-side-index: $theme2;
$color-heading-categorias-side-index: #FFF;

$bg-nomde-categoria-side: $theme;
$color-nomde-categoria-side: #FFF;

$color-categoria-side-hover: $theme2;

// CONTEUDO DA INDEX
$borda-box-destaques: $theme;
$borda-box: $theme;

$bg-btn-comprar: $theme;
$color-btn-comprar: #FFF;

$color-controls-produto: #FFF;
$bg-contorls-produto: $theme;

// RODAPE
$border-top-footer:12px solid $theme;
$bg-rodape:	#EDEDED;
$color-footer: #5F5C5C;

$border-title-footer: #B9B9B9;
$border-span-title-footer: $theme2;

// INTERNAS
$bg-heading-box-internas: $theme;
$color-heading-box-internas: #FFF;

$bg-box-internas-side: $theme;


$cor-bnt-pagamento: #FFF;
$bg-btn-pagamento: $theme;
$border-separador-btn-pagamento: #FFF;