main.index{
	padding-top:20px;
}

#banner{
	margin-bottom:40px;
	position:relative;
	z-index:0;

	@media (max-width: $screen-xs-max){
		margin-left:-15px;
		margin-right:-15px;
		margin-top:-20px;
	}

	.ctrls{
		position:absolute;
		width:100%;
		height:100%;
		display:flex;
		align-items:center;
		justify-content:center;
		top:0;
		left:0;

		.clearfix{
			width:100%;
			padding-left:15px;
			padding-right:15px;
		}

		a{
			position:relative;
			z-index:4;
		}

		.prev-banner{
			float:left;
		}

		.next-banner{
			float:right;
		}
	}

	.carousel-inner{
		position:relative;
		z-index:2;
	}
}

.box{
	background:#FFF;
	border-top:2px solid $borda-box;

	&.box-destaques{
		border-color: $borda-box-destaques;
	}

	.heading,
	.body{
		padding:10px;
	}

	.heading{
		border-bottom:1px solid #CCC;
	}

	.title-box{
		margin:0;
		font-size:22px;
		font-family: 'open_sanslight';
	}
}

.carousel-produtos-index{
	.heading{
		a{
			display: inline-block;
			padding:0 10px;

			& + a{
				margin-left:10px;
			}
		}
	}
}

#destaques{
	.owl-nav{
		position:absolute;
		right:0;
		bottom:100%;
		margin-bottom: 50px;

		&:before{
			content:'';
			display: block;
			border:1px solid #333;
			height:60%;
			left:50%;
			margin-left:-1px;
			top:20%;
			position:absolute;
		}
	}

	.owl-prev,
	.owl-next{
		display: inline-block;
		cursor:pointer;
		padding:6px 12px;
	}

	.owl-next{
		margin-left:15px;
	}
}

.controls-carousel-produtos{
	@include button-variant($color-controls-produto, $bg-contorls-produto,$bg-contorls-produto);
}