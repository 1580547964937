.row-login{
	@media (min-width: $screen-md-min){
		display:flex;

		& > .col-md-6{
			&:first-child{
				border-right:1px solid #CCC;
			}

			&:last-child{
				border-right:1px solid #CCC;
			}
		}

		form{
			padding:0 30px;
		}
	}
}

.form-login{
	text-align:center;

	.heading-login{
		display: inline-block;
		padding:10px 30px;
		font-size:20px;
		font-family: 'open_sansbold';
		background: #EEE;
		margin-bottom:20px;
	}

	.body-login{
		text-align:left;
	}
}

.toggle-type{
	border:none;
	background: none;
	outline: none !important;
	display:inline-flex;
	align-items:center;

	.circle{
		display: inline-block;
		width:1em;
		height:1em;
		border-radius:100%;
		border:1px solid #444;
		margin-right:10px;
		position:relative;
		background:#FFF;

		&:before{
			content:'';
			display: block;
			position:absolute;
			border:2px solid #FFF;
			background: $theme2;
			position:absolute;
			left:0;
			top:0;
			width:100%;
			height:100%;
			border-radius:100%;
			transform: scale(0);
			transition:all 0.25s linear;
		}
	}

	&.active{
		.circle:before{
			transform: scale(1);
		}
	}
}