.bar-topo{
	background: $bg-barra-topo;
	color: $color-barra-topo;
	padding:10px 0;

	.icon{
		margin-right:10px;
	}

	@media (min-width: $screen-sm-min){
		.container{
			display:flex;
			justify-content:space-between;

			&:after,
			&:before{
				display: none;
			}

			& > *{
				display:flex;
				align-items:center;
			}
		}
	}

	@media (max-width: $screen-xs-max){
		text-align:center;

		.fones,
		.whts{
			margin-bottom:10px;
		}

		.container > *{
			display:flex;
			align-items: center;
			justify-content:center;
		}
	}
}