.nav-content{
	position:fixed;
	top:0;
	width:220px;
	height:100%;
	overflow:auto;
	background: $bg-menu-open;
	left:-300px;
	z-index:1020;
	border-right:1px solid #CCC;

	.visible-when-open{
		display: block !important;

		&.btn{
			@include button-variant(#444,#FFF,#CCC)
		}
	}

	.brand{
		padding:30px 10px 20px 10px;
		border-bottom:1px solid #CCC;

		a{
			display: none;
		}
		
	}

	ul a{
		display: block;
		padding:10px 15px;
	}

	.btn-categorias{
		display: none;
	}

	.visible-when-open.btn{
		border-radius:0;
		padding:10px 15px;
		text-align:left;
	}

	.busca-por-categoria{
		display: none;
	}

	#menu{
		background:#EEE;

		a{
			border-top:1px solid transparent;
			border-bottom:1px solid transparent;
		}

		a:hover{
			text-decoration:none;
		}

		.active a,
		a:hover{
			background: $brand-primary;
			color:#FFF;
			border-color: rgba(#000,0.2);
		}
	}

	#utilits a{
		display:flex;
		align-items:center;
		color:#FFF;

		&:hover{
			color:#FFF;
			text-decoration:none;
		}

		.icon{
			margin-right:10px;
		}
	}

	.dropdown-menu{
		position:relative;
		width:100%;

		background: #EEE;

		.active .nome-categoria,
		.open .nome-categoria,
		.active:hover .nome-categoria{
			background: $brand-primary;

			a{
				color:#FFF;
				text-decoration:none;
			}

			.btn{
				@include button-variant(#FFF,darken($brand-primary,5%),darken($brand-primary,10%));
			}
		}

		li{
			border-bottom:1px solid #CCC;
		}

		.dropdown-menu{
			background: darken(#EEE, 10%);

			.active a,
			a:hover{
				background: $brand-primary;
				color:#FFF;
			}
		}
	}

	.categorias{
		margin-bottom:60px;
	}
}