.material-form{
	position:relative;
	padding-top:20px;

	label{
		font-weight:100;
		position:absolute;
		padding:10px 5px;
		top:20px;
		transition:top 0.3s linear;
	}

	input,
	textarea{
		display: block;
		width:100%;
		border:none;
		box-shadow:none;
		padding:10px 5px;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;

		&:focus{
			outline:none;
			box-shadow: 0 0 8px materialColor('indigo','darken-3');
		}

		&:focus + .hover-eff{
			&:before{
				width:100%;
			}
		}
	}

	input{
		height:35px;
	}

	.hover-eff{
		height:3px;
		background: #CCC;

		&:before{
			content:'';
			display: block;
			width:0;
			height:3px;
			background:	materialColor('green', 'darken-3');
			transition:width 0.3s linear;
		}
	}

	&.focus{
		.hover-eff:before{
			width:100%;
		}

		label{
			top:0;
			padding-top:0px;
			font-size:12px;
		}
	}

	.mensagem-erro{
		margin-top:5px;
		color: materialColor('red','darken-3');
	}

	&.error{
		.hover-eff:before{
			width:100%;
			background: materialColor('red','darken-3');
		}

		label{
			top:0;
			padding-top:0px;
			font-size:12px;
			color: materialColor('red','darken-3');
		}
	}
}