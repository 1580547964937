@font-face {
    font-family: 'open_sanslight';
    src: url('#{$fonts}/opensans-light/opensans-light_0-webfont.eot');
    src: url('#{$fonts}/opensans-light/opensans-light_0-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-light/opensans-light_0-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans_regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans_regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansbold';
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot');
    src: url('#{$fonts}/opensans-bold/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-bold/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}